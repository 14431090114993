import React from 'react';
import { ProGallery } from 'pro-gallery/dist/es/src/index';
import experiments from '@wix/photography-client-lib/dist/src/sdk/experimentsWrapper';
import window from '@wix/photography-client-lib/dist/src/sdk/windowWrapper';
import '../styles/index';
import { utils } from '../utils';
import EventHandler from './helpers/eventHandler';
import SiteHelper from './helpers/siteHelper';
import DimensionsHelper from './helpers/dimensionsHelper';
import FullscreenHelper from './helpers/fullscreenHelper';
import LogHelper from './helpers/logHelper';
import ItemsHelper from './helpers/itemsHelper';
import AccessibilityHelper from './helpers/accessibilityHelper';

export default class CommonGalleryWrapper extends React.Component {
  constructor(props) {
    props = { ...props.host, ...props }; // untill props.host will be fully active sv_addPropsToHostInNativeComponent
    super(props);
    this.avoidGallerySelfMeasure = true; //the wrapper is measuring for the gallery
    this.state = {
      fullscreen: {
        clickedIdx: props.clickedIdx >= 0 ? props.clickedIdx : -1,
        fullscreenAnimating: false,
        directFullscreenItem:
          !window.firstProGalleryRender && props.directFullscreenItem,
      },
      isAccessible: false,
      itemsLoveData: {},
      container: {
        ...props.dimensions,
        avoidGallerySelfMeasure: this.avoidGallerySelfMeasure,
      },
    };
    const isStoreGallery = this.isStoreGallery();
    this.siteHelper = new SiteHelper(this, props, isStoreGallery);
    this.dimensionsHelper = new DimensionsHelper(this, props);
    this.logHelper = new LogHelper(
      this,
      props,
      this.getSentryDSN(),
      isStoreGallery,
    );
    this.itemsHelper = new ItemsHelper(this, props, isStoreGallery);
    this.fullscreenHelper = new FullscreenHelper(this, props, isStoreGallery);
    this.accessibilityHelper = new AccessibilityHelper(this, props);
    this.eventHandler = new EventHandler(this, props);

    this.onNewProps(props);

    this.generateApiIfNeeded();
    window.firstProGalleryRender = true;
  }
  //return true if art-store and false for pro-gallery
  isStoreGallery() {
    return false;
  }
  //fullscreen wrapper for OOI apps
  getFullscreenWrapperElement() {
    return null;
  }
  //sentry dsn for the app
  getSentryDSN() {
    return '';
  }
  //item resizer - with watermark for art-store
  getItemResizer() {
    return null;
  }
  // props that are passed for fullscreen wrapper
  getArtStoreProps() {
    return {};
  }
  //watermark for pro-gallery (if false no watermark will be send)
  getWatermark() {
    return false;
  }
  //end of common methods
  componentDidMount() {
    this.eventHandler.initComponentDidLayout();
    this.siteHelper.loadUserFonts();
    this.itemsHelper.initItemActions();
    this.accessibilityHelper.initAccessibility();
    this.dimensionsHelper.createResizeObserver();

    this.onNewProps(this.props);
  }

  componentWillReceiveProps(props) {
    this.onNewProps(props);
  }

  componentWillUnmount() {
    this.accessibilityHelper.cleanupAccessibility();
  }

  onNewProps(props) {
    props = { ...props.host, ...props }; // untill props.host will be fully active sv_addPropsToHostInNativeComponent
    this.dimensionsHelper.update(props);
    this.siteHelper.update(props);
    this.fullscreenHelper.update(props);
    this.logHelper.update(props);
    this.itemsHelper.update(props);
    this.accessibilityHelper.update(props);
  }

  generateApiIfNeeded() {
    try {
      if (utils.shouldDebug('albums_api') || utils.isInAlbumsBuilder()) {
        console.log('Generating API for Albums in window.AlbumsGalleryApi');
        window.AlbumsGalleryApi = {
          getItems: () => this.itemsHelper.pgItemsProps().items,
          setItems: items => {
            this.setState({ manualItems: items });
          },
          getOptions: () => this.siteHelper.getPGStyles(),
          setOptions: styleParams => {
            this.setState({ manualStyleParams: styleParams });
          },
        };
        window.addEventListener(
          'message',
          event => {
            try {
              console.log('Message arrived', event);
              const { method, data } = event.data;
              window.AlbumsGalleryApi[method](data);
            } catch (err) {
              console.log('Bad message arrived', err, event);
            }
          },
          false,
        );
      }
    } catch (e) {
      console.error('Could not create Manual Api', e);
    }
  }

  render() {
    const props = { ...this.props.host, ...this.props }; // until props.host will be fully active sv_addPropsToHostInNativeComponent
    const {
      queryParams,
      notInView,
      id,
      galleryId,
      forceHover,
      viewMode,
    } = props;

    if (
      window.isSSR &&
      ((experiments && experiments('specs.pro-gallery.skipSsr') === 'true') ||
        (queryParams && queryParams.skipPgSsr === 'true'))
    ) {
      console.error('Skipping Pro Gallery SSR!', this.props);
      return <div />;
    }

    if (utils.isSSR() && notInView) {
      if (utils.isVerbose()) {
        console.log('PG not in view, skipping');
      }
      return <div id={`gallery-wrapper-${id}`} key={`gallery-wrapper-${id}`} />;
    }

    if (utils.isVerbose()) {
      console.log('Pro Gallery wrapper!', this.props);
      console.count('[OOISSR] proGallery ooi wrapper render');
    }

    this.pgProps = {
      domId: id,
      galleryId,
      allowSSR: true,
      container: this.state.container,
      forceHover,
      noFollowForSEO: !this.siteHelper.isPremiumSite(),
      viewMode: this.siteHelper.parseViewMode(viewMode),
      scrollingElement: this.siteHelper.getScrollingElement(),
      itemsLoveData: this.state.itemsLoveData,
      resizeMediaUrl: this.getItemResizer(),
    };
    if (this.getWatermark()) {
      this.pgProps.watermark = this.getWatermark();
    }
    if (this.itemsHelper.pgItemsProps().length === 0) {
      this.eventHandler.setZeroItems();
    }
    const dom = [
      <ProGallery
        key="pro-gallery"
        ref={node => (this.node = node)}
        styles={this.siteHelper.getPGStyles()}
        eventsListener={this.eventHandler.handleEvent}
        {...this.pgProps}
        {...this.itemsHelper.pgItemsProps()}
      />,
    ];
    const ProFullscreenWrapper = this.getFullscreenElement();
    dom.push(
      <ProFullscreenWrapper
        {...this.pgProps}
        {...this.getArtStoreProps()}
        {...this.fullscreenHelper.fullscreenItemsProps()}
        key="pro-fullscreen"
        styleParams={this.siteHelper.getFullscreenStyles()}
        scrollTo={this.props.scrollTo}
        fullscreenAnimating={this.state.fullscreen.fullscreenAnimating}
        fullscreenIdx={
          this.state.fullscreen.directFullscreenItem &&
          this.state.fullscreen.directFullscreenItem.itemId
            ? 0
            : this.state.fullscreen.clickedIdx
        }
        eventsListener={this.eventHandler.handleFullscreenEvent}
      />,
    );

    return (
      <div id={`gallery-wrapper-${id}`} key={`gallery-wrapper-${id}`}>
        {dom}
      </div>
    );
  }
}
